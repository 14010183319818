/*

 */

.viewPage {
  font-family: "Spartan", san-serif;
  color: #000;
  background-color: #e5ddc5;
}

.pageContent {
  margin: 40px 0px;
  min-height: 90vh;
}

a,
img {
  /* grows all links on hover */
  transition: all 0.2s ease-in-out;
}
a:hover,
img:hover {
  transform: scale(1.1);
}

.row {
  --bs-gutter-x: unset !important;
}
/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  a,
  img {
    /* grows all links on hover */
    transition: unset;
  }
  a:hover,
  img:hover {
    transform: unset;
  }
}
