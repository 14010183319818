.justify-content-center {
  background-color: #e5ddc5;
}
h2 {
  letter-spacing: 10px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  h2 {
    letter-spacing: 3px;
  }
}
